import React from 'react';
import { CToast, CToastBody } from '@coreui/react';

export enum ToastType {
  Info = 'info',
  Success = 'success',
  Danger = 'danger',
}

type ToastProps = {
  id: string;
  autoHide: number;
  message: string;
  onHide(id: string): void;
  type: ToastType;
};

function Toast(props: ToastProps) {
  const onStateChange = (state: boolean | string) => {
    if (state === false) {
      props.onHide(props.id);
    }
  };

  let backgroundClassNames = 'bg-info';
  if (props.type === ToastType.Success) {
    backgroundClassNames = 'bg-success';
  } else if (props.type === ToastType.Danger) {
    backgroundClassNames = 'bg-danger';
  }

  return (
    <CToast
      key={props.id}
      className={backgroundClassNames}
      show={true}
      autohide={props.autoHide}
      fade
      onStateChange={onStateChange}>
      <CToastBody className="text-white">{props.message}</CToastBody>
    </CToast>
  );
}

export default Toast;
