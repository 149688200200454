import React from 'react';
import { useTypedSelector } from '../../store/utils';
import { getAllToasts } from '../../store/toasts/selector';
import Toast from './Toast';
import { CToaster } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { hideToast } from '../../store/toasts/actions';

function ToastsContainer() {
  const toasts = Object.values(useTypedSelector(getAllToasts));

  const dispatch = useDispatch();

  const onHide = (id: string) => dispatch(hideToast(id));

  return (
    <CToaster position="top-right">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          autoHide={3000}
          message={toast.message}
          onHide={onHide}
          type={toast.type}
        />
      ))}
    </CToaster>
  );
}

export default ToastsContainer;
