import {
  CreateSensoryAnalysis,
  DeleteSensoryAnalysis,
  GetSensoryAnalyses,
  GetSensoryAnalysesForOrg,
  GetSensoryAnalysis,
  SensoryActionTypes,
  UpdateSensoryAnalysis,
} from './types';
import { SensoryAnalysis } from '../../models/sensory';

export function getSensoryAnalyses(): SensoryActionTypes {
  return {
    type: GetSensoryAnalyses,
    payload: {},
  };
}

export function getSensoryAnalysesForOrg(orgId?: string): SensoryActionTypes {
  return {
    type: GetSensoryAnalysesForOrg,
    payload: { orgId },
  };
}

export function getSensoryAnalysis(id: string): SensoryActionTypes {
  return {
    type: GetSensoryAnalysis,
    payload: { id },
  };
}

export function updateSensoryAnalysis(
  id: string,
  sensory: SensoryAnalysis,
): SensoryActionTypes {
  return {
    type: UpdateSensoryAnalysis,
    payload: { id, sensory },
  };
}

export function createSensoryAnalysis(
  sensory: SensoryAnalysis,
): SensoryActionTypes {
  return {
    type: CreateSensoryAnalysis,
    payload: { sensory },
  };
}

export function deleteSensoryAnalysis(id: string): SensoryActionTypes {
  return {
    type: DeleteSensoryAnalysis,
    payload: { id },
  };
}
