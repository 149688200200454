import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { isProduction } from '../../utils/config';

let firebaseConfig;
if (!isProduction()) {
  firebaseConfig = {
    apiKey: 'AIzaSyBHi7mbP-gO-QJKkSEPQu2I3sDl1ygyN1E',
    authDomain: 'dlab-dev.firebaseapp.com',
    databaseURL: 'https://dlab-dev.firebaseio.com',
    projectId: 'dlab-dev',
    storageBucket: 'dlab-dev.appspot.com',
    messagingSenderId: '319326569562',
    appId: '1:319326569562:web:1a0c172fd18fc4526ec463',
    measurementId: 'G-DRP02QWDWQ',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyBIm9pAbwJyTCvIU5QuFrrczsyWUG3NYV0',
    authDomain: 'dlab-287315.firebaseapp.com',
    databaseURL: 'https://dlab-287315.firebaseio.com',
    projectId: 'dlab-287315',
    storageBucket: 'dlab-287315.appspot.com',
    messagingSenderId: '697857244649',
    appId: '1:697857244649:web:6fb9b6b8d4521ee4679f78',
    measurementId: 'G-3Q4W212K5Y',
  };
}

// Initializing Firebase app
firebase.initializeApp(firebaseConfig);
firebase.analytics();
