import { Dispatch, MiddlewareAPI } from 'redux';
import {
  CreateSensoryAnalysis,
  CreateSensoryAnalysisAction,
  DeleteSensoryAnalysis,
  DeleteSensoryAnalysisAction,
  GetSensoryAnalyses,
  GetSensoryAnalysesForOrg,
  GetSensoryAnalysis,
  GetSensoryAnalysisAction,
  SensoryActionTypes,
  UpdateSensoryAnalysis,
  UpdateSensoryAnalysisAction,
} from './types';
import {
  apiCreateSensoryAnalysis,
  apiDeleteSensoryAnalysis,
  apiGetSensoryAnalysesForOrganization,
  apiGetSensoryAnalysesForOrganizationById,
  apiGetSensoryAnalysesForUser,
  apiGetSensoryAnalysis,
  apiUpdateSensoryAnalysis,
} from '../api/actions';
import { history } from '../../components/router/CustomBrowserRouter';
import {
  APICreateSensoryAnalysis,
  APIDeleteSensoryAnalysis,
  APIUpdateSensoryAnalysis,
} from '../api/types';
import { isMasterUser, isOrgManager } from '../user/selector';
import { AppState } from '../utils';
import { showToast } from '../toasts/actions';
import { generateUUID } from '../../utils/string';
import langManager from '../../languages/LanguagesManager';
import { ToastType } from '../../components/toasts/Toast';

const getSensoryAnalysesForUserHandler = (store: MiddlewareAPI) => {
  const appState: AppState = store.getState();

  const isMaster = isMasterUser(appState);
  if (isMaster) {
    const orgId = appState.master.selectedOrganizationId;
    if (orgId.length === 0) {
      store.dispatch(apiGetSensoryAnalysesForOrganization());
    } else {
      store.dispatch(apiGetSensoryAnalysesForOrganizationById(orgId));
    }
  } else {
    const orgManager = isOrgManager(store.getState());
    if (orgManager) {
      store.dispatch(apiGetSensoryAnalysesForOrganization());
    } else {
      store.dispatch(apiGetSensoryAnalysesForUser());
    }
  }
};

const getSensoryAnalysisHandler = (
  store: MiddlewareAPI,
  action: GetSensoryAnalysisAction,
) => {
  store.dispatch(apiGetSensoryAnalysis(action.payload.id));
};

const updateSensoryAnalysisHandler = (
  store: MiddlewareAPI,
  action: UpdateSensoryAnalysisAction,
) => {
  store.dispatch(
    apiUpdateSensoryAnalysis(action.payload.id, action.payload.sensory),
  );
};

const updateSensoryAnalysisCompletedHandler = (
  store: MiddlewareAPI,
  sensoryId: string,
) => {
  store.dispatch(
    showToast(
      generateUUID(),
      langManager.getStr('common.savedSuccess'),
      ToastType.Success,
    ),
  );

  history.replace(`/sensory/${sensoryId}`);
};

const createSensoryAnalysisHandler = (
  store: MiddlewareAPI,
  action: CreateSensoryAnalysisAction,
) => {
  store.dispatch(apiCreateSensoryAnalysis(action.payload.sensory));
};

const deleteSensoryAnalysisHandler = (
  store: MiddlewareAPI,
  action: DeleteSensoryAnalysisAction,
) => {
  store.dispatch(apiDeleteSensoryAnalysis(action.payload.id));
};

const sensoryMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: SensoryActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case GetSensoryAnalyses:
    case GetSensoryAnalysesForOrg: {
      getSensoryAnalysesForUserHandler(store);
      break;
    }
    case GetSensoryAnalysis: {
      getSensoryAnalysisHandler(store, action as GetSensoryAnalysisAction);
      break;
    }
    case UpdateSensoryAnalysis: {
      updateSensoryAnalysisHandler(
        store,
        action as UpdateSensoryAnalysisAction,
      );
      break;
    }
    case APIUpdateSensoryAnalysis.SUCCESS: {
      const data: any = action.payload;
      updateSensoryAnalysisCompletedHandler(store, data.id);

      break;
    }
    case APIUpdateSensoryAnalysis.FAILURE: {
      // TODO: show error

      break;
    }
    case CreateSensoryAnalysis: {
      createSensoryAnalysisHandler(
        store,
        action as CreateSensoryAnalysisAction,
      );
      break;
    }
    case APICreateSensoryAnalysis.SUCCESS: {
      const data: any = action.payload;
      updateSensoryAnalysisCompletedHandler(store, data.id);

      break;
    }
    case APICreateSensoryAnalysis.FAILURE: {
      // TODO: show error

      break;
    }
    case DeleteSensoryAnalysis: {
      deleteSensoryAnalysisHandler(
        store,
        action as DeleteSensoryAnalysisAction,
      );
      break;
    }
    case APIDeleteSensoryAnalysis.SUCCESS: {
      getSensoryAnalysesForUserHandler(store);

      break;
    }
    case APIDeleteSensoryAnalysis.FAILURE: {
      // TODO: show error

      break;
    }
    default:
      break;
  }
};

export default sensoryMiddleware;
