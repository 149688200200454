import { DeepReadonly } from 'utility-types';
import { Language } from '../../languages/LanguagesManager';
import { withPrefix } from '../utils';

const Name = 'Settings';

export const ChangeLanguage = withPrefix(Name, 'ChangeLanguage');

// Actions types
export interface ChangeLanguageAction {
  type: typeof ChangeLanguage;
  payload: { language: Language };
}

export type SettingsActionTypes = ChangeLanguageAction;

// Reducer state
export type SettingsState = DeepReadonly<{
  readonly language: Language;
}>;
