import { Dispatch, MiddlewareAPI } from 'redux';
import {
  AddUserToOrganization,
  AddUserToOrganizationAction,
  CreateOrganization,
  CreateOrganizationAction,
  GetOrganization,
  GetOrganizationAction,
  GetOrganizations,
  GetOrganizationUsers,
  GetOrganizationUsersAction,
  OrganizationsActionTypes,
  RemoveUserFromOrganization,
  RemoveUserFromOrganizationAction,
  UpdateOrganization,
  UpdateOrganizationAction,
  UpdateUserInOrganization,
  UpdateUserInOrganizationAction,
} from './types';
import {
  apiAddUserToOrganization,
  apiCreateOrganization,
  apiGetOrganization,
  apiGetOrganizations,
  apiGetOrganizationUsers,
  apiRemoveUserFromOrganization,
  apiUpdateOrganization,
  apiUpdateUserInOrganization,
} from '../api/actions';
import { history } from '../../components/router/CustomBrowserRouter';
import {
  APIAddUserToOrganization,
  APIAddUserToOrganizationAction,
  APICreateOrganization,
  APIUpdateOrganization,
  APIUpdateUserInOrganization,
} from '../api/types';
import { isMasterUser } from '../user/selector';

const getOrganizationsHandler = (store: MiddlewareAPI) => {
  store.dispatch(apiGetOrganizations());
};

const getOrganizationHandler = (
  store: MiddlewareAPI,
  action: GetOrganizationAction,
) => {
  store.dispatch(apiGetOrganization(action.payload.id));
};

const createOrganizationHandler = (
  store: MiddlewareAPI,
  action: CreateOrganizationAction,
) => {
  store.dispatch(
    apiCreateOrganization(action.payload.name, action.payload.products),
  );
};

const updateOrganizationHandler = (
  store: MiddlewareAPI,
  action: UpdateOrganizationAction,
) => {
  store.dispatch(
    apiUpdateOrganization(
      action.payload.id,
      action.payload.name,
      action.payload.products,
    ),
  );
};

const getOrganizationUsersHandler = (
  store: MiddlewareAPI,
  action: GetOrganizationUsersAction,
) => {
  store.dispatch(apiGetOrganizationUsers(action.payload.id));
};

const addUserToOrganizationHandler = (
  store: MiddlewareAPI,
  action: AddUserToOrganizationAction,
) => {
  store.dispatch(
    apiAddUserToOrganization(
      action.payload.orgId,
      action.payload.email,
      action.payload.firstName,
      action.payload.lastName,
      action.payload.password,
      action.payload.roles,
    ),
  );
};

const updateUserInOrganizationHandler = (
  store: MiddlewareAPI,
  action: UpdateUserInOrganizationAction,
) => {
  store.dispatch(
    apiUpdateUserInOrganization(
      action.payload.orgId,
      action.payload.userId,
      action.payload.firstName,
      action.payload.lastName,
      action.payload.roles,
    ),
  );
};

const removeUserFromOrganizationHandler = (
  store: MiddlewareAPI,
  action: RemoveUserFromOrganizationAction,
) => {
  store.dispatch(
    apiRemoveUserFromOrganization(action.payload.orgId, action.payload.userId),
  );
};

const onOrganizationSavedHandler = () => {
  history.replace('/organizations');
};

const onUserSavedHandler = (
  store: MiddlewareAPI,
  action: APIAddUserToOrganizationAction,
) => {
  const { orgId } = action.requestPayload;

  const isMaster = isMasterUser(store.getState());

  if (isMaster) {
    history.replace(`/organizations/${orgId}`);
  } else {
    history.replace(`/organizations/${orgId}/users`);
  }
};

const organizationsMiddleware = (store: MiddlewareAPI) => (
  next: Dispatch,
) => async (action: OrganizationsActionTypes) => {
  next(action);

  const { type } = action;

  switch (type) {
    case GetOrganizations: {
      getOrganizationsHandler(store);
      break;
    }
    case GetOrganization: {
      getOrganizationHandler(store, action as GetOrganizationAction);
      break;
    }
    case CreateOrganization: {
      createOrganizationHandler(store, action as CreateOrganizationAction);
      break;
    }
    case UpdateOrganization: {
      updateOrganizationHandler(store, action as UpdateOrganizationAction);
      break;
    }
    case GetOrganizationUsers: {
      getOrganizationUsersHandler(store, action as GetOrganizationUsersAction);
      break;
    }
    case AddUserToOrganization: {
      addUserToOrganizationHandler(
        store,
        action as AddUserToOrganizationAction,
      );
      break;
    }
    case UpdateUserInOrganization: {
      updateUserInOrganizationHandler(
        store,
        action as UpdateUserInOrganizationAction,
      );
      break;
    }
    case RemoveUserFromOrganization: {
      removeUserFromOrganizationHandler(
        store,
        action as RemoveUserFromOrganizationAction,
      );
      break;
    }
    case APICreateOrganization.SUCCESS:
    case APIUpdateOrganization.SUCCESS: {
      onOrganizationSavedHandler();

      break;
    }
    case APIAddUserToOrganization.SUCCESS:
    case APIUpdateUserInOrganization.SUCCESS: {
      onUserSavedHandler(store, action as APIAddUserToOrganizationAction);

      break;
    }
    default:
      break;
  }
};

export default organizationsMiddleware;
