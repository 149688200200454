import {
  HideToast,
  HideToastAction,
  ShowToast,
  ShowToastAction,
  ToastsActionTypes,
  ToastsState,
} from './types';

const initialState: ToastsState = {
  all: {},
};

function reducer(state: ToastsState, action: ToastsActionTypes): ToastsState {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case ShowToast: {
      const { id, message, type } = (action as ShowToastAction).payload;

      return {
        ...state,
        all: {
          ...state.all,
          [id]: {
            id,
            message,
            type,
          },
        },
      };
    }
    case HideToast: {
      const { id } = (action as HideToastAction).payload;

      const { [id]: _, ...withoutHiddenToast } = state.all;

      return {
        ...state,
        all: withoutHiddenToast,
      };
    }
    default:
      break;
  }
  return state;
}

export default reducer;
