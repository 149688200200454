import { withPrefix } from '../utils';
import { SensoryAnalysis } from '../../models/sensory';

const Name = 'Sensory';

export const GetSensoryAnalyses = withPrefix(Name, 'GetSensoryAnalyses');
export const GetSensoryAnalysesForOrg = withPrefix(
  Name,
  'GetSensoryAnalysesForOrg',
);
export const GetSensoryAnalysis = withPrefix(Name, 'GetSensoryAnalysis');
export const UpdateSensoryAnalysis = withPrefix(Name, 'UpdateSensoryAnalysis');
export const CreateSensoryAnalysis = withPrefix(Name, 'CreateSensoryAnalysis');
export const DeleteSensoryAnalysis = withPrefix(Name, 'DeleteSensoryAnalysis');

// Actions types
export interface GetSensoryAnalysesAction {
  type: typeof GetSensoryAnalyses;
  payload: {};
}

export interface GetSensoryAnalysesForOrgAction {
  type: typeof GetSensoryAnalysesForOrg;
  payload: { orgId?: string };
}

export interface GetSensoryAnalysisAction {
  type: typeof GetSensoryAnalysis;
  payload: { id: string };
}

export interface UpdateSensoryAnalysisAction {
  type: typeof UpdateSensoryAnalysis;
  payload: { id: string; sensory: SensoryAnalysis };
}

export interface CreateSensoryAnalysisAction {
  type: typeof CreateSensoryAnalysis;
  payload: { sensory: SensoryAnalysis };
}

export interface DeleteSensoryAnalysisAction {
  type: typeof DeleteSensoryAnalysis;
  payload: { id: string };
}

export type SensoryActionTypes =
  | GetSensoryAnalysesAction
  | GetSensoryAnalysesForOrgAction
  | GetSensoryAnalysisAction
  | UpdateSensoryAnalysisAction
  | CreateSensoryAnalysisAction
  | DeleteSensoryAnalysisAction;

// Reducer state
export type SensoryState = {
  loadingAnalyses: boolean;
  analysesIds: Array<string>;
  analyses: { [key: string]: SensoryAnalysis };
  loadingCurrentAnalysis: boolean;
  savingCurrentAnalysis: boolean;
};

// TODO: remove the use of the following in the entire app
// import { DeepReadonly } from 'utility-types';

// TODO: make sure to use useTypedSelector and not the regular useSelector
