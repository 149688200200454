import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilCoffee,
  cilHouse,
  cilPeople,
  cilUser,
  cilLockLocked,
  cilAccountLogout,
} from '@coreui/icons/js/free';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilCoffee,
    cilHouse,
    cilPeople,
    cilUser,
    cilLockLocked,
    cilAccountLogout,
  },
);
