import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Every time our browser url path changes, we would like to
// automatically scroll the page to top.
const ScrollToTop = () => {
  const pathname: string = useLocation().pathname;

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return null;
};

export default ScrollToTop;
