import { ChangeVisibility, SideBarActionTypes, SideBarState } from './types';

const initialState: SideBarState = {
  show: 'responsive',
};

function reducer(
  state: SideBarState,
  action: SideBarActionTypes,
): SideBarState {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case ChangeVisibility: {
      const { show } = payload;

      return {
        ...state,
        show,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
