import { Dispatch, MiddlewareAPI } from 'redux';
import {
  CreateGranulometryAnalysis,
  CreateGranulometryAnalysisAction,
  GetGranulometryAnalysis,
  GetGranulometryAnalysisAction,
  GranulometryActionTypes,
  UpdateGranulometryAnalysis,
  UpdateGranulometryAnalysisAction,
} from './types';
import {
  apiCreateGranulometryAnalysis,
  apiGetGranulometryAnalysis,
  apiUpdateGranulometryDefectsAnalysis,
} from '../api/actions';
import {
  APICreateGranulometryAnalysis,
  APIUpdateGranulometryAnalysis,
} from '../api/types';
import { showToast } from '../toasts/actions';
import { generateUUID } from '../../utils/string';
import langManager from '../../languages/LanguagesManager';
import { ToastType } from '../../components/toasts/Toast';

const getGranulometryAnalysisHandler = (
  store: MiddlewareAPI,
  action: GetGranulometryAnalysisAction,
) => {
  store.dispatch(
    apiGetGranulometryAnalysis(
      action.payload.companyCode,
      action.payload.userId,
    ),
  );
};

const createGranulometryAnalysisHandler = (
  store: MiddlewareAPI,
  action: CreateGranulometryAnalysisAction,
) => {
  const payload = action.payload;
  store.dispatch(
    apiCreateGranulometryAnalysis(
      payload.companyCode,
      payload.mesh1,
      payload.mesh2,
      payload.mesh3,
      payload.mesh4,
      payload.mesh5,
      payload.mesh6,
      payload.mesh7,
      payload.final,
      payload.totalWeight,
    ),
  );
};

const updateGranulometryAnalysisHandler = (
  store: MiddlewareAPI,
  action: UpdateGranulometryAnalysisAction,
) => {
  const payload = action.payload;
  store.dispatch(
    apiUpdateGranulometryDefectsAnalysis(
      payload.id,
      payload.companyCode,
      payload.mesh1,
      payload.mesh2,
      payload.mesh3,
      payload.mesh4,
      payload.mesh5,
      payload.mesh6,
      payload.mesh7,
      payload.final,
      payload.totalWeight,
    ),
  );
};

const updateGranulometryAnalysisCompletedHandler = (store: MiddlewareAPI) => {
  store.dispatch(
    showToast(
      generateUUID(),
      langManager.getStr('common.savedSuccess'),
      ToastType.Success,
    ),
  );
};

const granulometryMiddleware = (store: MiddlewareAPI) => (
  next: Dispatch,
) => async (action: GranulometryActionTypes) => {
  next(action);

  const { type } = action;

  switch (type) {
    case GetGranulometryAnalysis: {
      getGranulometryAnalysisHandler(
        store,
        action as GetGranulometryAnalysisAction,
      );
      break;
    }
    case CreateGranulometryAnalysis: {
      createGranulometryAnalysisHandler(
        store,
        action as CreateGranulometryAnalysisAction,
      );
      break;
    }
    case UpdateGranulometryAnalysis: {
      updateGranulometryAnalysisHandler(
        store,
        action as UpdateGranulometryAnalysisAction,
      );
      break;
    }
    case APICreateGranulometryAnalysis.SUCCESS:
    case APIUpdateGranulometryAnalysis.SUCCESS: {
      updateGranulometryAnalysisCompletedHandler(store);
      break;
    }
    default:
      break;
  }
};

export default granulometryMiddleware;
