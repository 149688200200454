import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducers
import settings from './settings/reducer';
import sidebar from './sidebar/reducer';
import user from './user/reducer';
import sensory from './sensory/reducer';
import organizations from './organizations/reducer';
import toasts from './toasts/reducer';
import defects from './defects/reducer';
import granulometry from './granulometry/reducer';
import master from './master/reducer';
import exportData from './export/reducer';

// Middlewares
import settingsMiddleware from './settings/middleware';
import userMiddleware from './user/middleware';
import apiMiddleware from './api/middleware';
import sensoryMiddleware from './sensory/middleware';
import organizationsMiddleware from './organizations/middleware';
import defectsMiddleware from './defects/middleware';
import granulometryMiddleware from './granulometry/middleware';
import masterMiddleware from './master/middleware';
import exportMiddleware from './export/middleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings'],
};

const rootReducer = combineReducers({
  settings,
  sidebar,
  user,
  sensory,
  organizations,
  toasts,
  defects,
  granulometry,
  master,
  exportData,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [
  settingsMiddleware,
  userMiddleware,
  apiMiddleware,
  sensoryMiddleware,
  organizationsMiddleware,
  defectsMiddleware,
  granulometryMiddleware,
  masterMiddleware,
  exportMiddleware,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

// @ts-ignore
const persistor = persistStore(store);

export { store, persistor };
