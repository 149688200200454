import { DeepReadonly } from 'utility-types';
import { withPrefix } from '../utils';

const Name = 'SideBar';

export const ChangeVisibility = withPrefix(Name, 'ChangeVisibility');

// Actions types
export interface ChangeVisibilityAction {
  type: typeof ChangeVisibility;
  payload: { show: boolean | 'responsive' };
}

export type SideBarActionTypes = ChangeVisibilityAction;

// Reducer state
export type SideBarState = DeepReadonly<{
  readonly show: 'responsive' | boolean;
}>;
