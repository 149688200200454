import { Dispatch, MiddlewareAPI } from 'redux';
import FileSaver from 'file-saver';
import { ExportActionTypes, ExportToExcel } from './types';
import { apiExportDataToExcel } from '../api/actions';
import {
  APIActionTypes,
  APIExportDataToExcel,
  APIExportDataToExcelAction,
} from '../api/types';
import {isMasterUser} from "../user/selector";

const exportToExcelHandler = (store: MiddlewareAPI) => {
  const appState = store.getState();
  const isMaster = isMasterUser(appState);

  let orgId = null;
  if (isMaster) {
    orgId = appState.master.selectedOrganizationId;
  }

  store.dispatch(apiExportDataToExcel(orgId));
};

const exportToExcelSuccessHandler = (
  store: MiddlewareAPI,
  action: APIExportDataToExcelAction,
) => {
  const data = action.payload;
  const { excelData } = data;

  const blob: Blob = new Blob([Buffer.from(excelData.data)], {
    type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(blob, `dlab-export-${Date.now()}.xlsx`);
};

const exportMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: ExportActionTypes | APIActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case ExportToExcel: {
      exportToExcelHandler(store);
      break;
    }
    case APIExportDataToExcel.SUCCESS: {
      exportToExcelSuccessHandler(store, action as APIExportDataToExcelAction);
      break;
    }
    default:
      break;
  }
};

export default exportMiddleware;
