import { withPrefix } from '../utils';
import { ToastType } from '../../components/toasts/Toast';

const Name = 'Toasts';

export const ShowToast = withPrefix(Name, 'ShowToast');
export const HideToast = withPrefix(Name, 'HideToast');

// Actions types
export interface ShowToastAction {
  type: typeof ShowToast;
  payload: { id: string; message: string; type: ToastType };
}

export interface HideToastAction {
  type: typeof HideToast;
  payload: { id: string };
}

export type ToastsActionTypes = ShowToastAction | HideToastAction;

// Reducer state
export type ToastsState = {
  all: {
    [key: string]: {
      id: string;
      message: string;
      type: ToastType;
    };
  };
};
