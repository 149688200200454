import {
  ChangeSelectedOrganization,
  MasterActionTypes,
  MasterSettings,
} from './types';

const initialState: MasterSettings = {
  selectedOrganizationId: '',
};

function reducer(
  state: MasterSettings,
  action: MasterActionTypes,
): MasterSettings {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case ChangeSelectedOrganization: {
      const { orgId } = payload;

      return {
        ...state,
        selectedOrganizationId: orgId,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
