import { ExportActionTypes, ExportState } from './types';
import { APIActionTypes } from '../api/types';

const initialState: ExportState = {
  excelDate: null,
  exporting: false,
  exportError: null,
};

function reducer(
  state: ExportState,
  action: ExportActionTypes | APIActionTypes,
) {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    default:
      break;
  }

  return state;
}

export default reducer;
