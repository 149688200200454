import * as firebase from 'firebase/app';
import { MiddlewareAPI, Dispatch } from 'redux';
import {
  LoginSuccessful,
  LoginWithEmailPassword,
  LoginWithEmailPasswordAction,
  Logout,
  SilentLogin,
  UserActionTypes,
  UserLoginError,
} from './types';
import { loginFailed, loginSuccessful } from './actions';
import { history } from '../../components/router/CustomBrowserRouter';
import { getUrlParameter } from '../../utils/browser';
import { apiUserConnect } from '../api/actions';
import { APIUserConnect } from '../api/types';
import { setUser } from '../../services/bugsnag/bugsnag';

// Firebase auth listener - will get called on user login / logout.
const onAuthStateChanged = (store: MiddlewareAPI) => async (
  user: firebase.User | null,
) => {
  if (user !== null) {
    const userFirebaseId = user.uid;
    const userEmail = user.email || '';
    const userName = user.displayName || '';

    setUser(userFirebaseId, userEmail, userName);

    const idToken = await user.getIdToken();
    store.dispatch(
      loginSuccessful(
        idToken,
        userFirebaseId,
        userName,
        userEmail,
        user.photoURL || '',
      ),
    );
  }
};

// Change app language.
// Every component which is bind to the store, will get re-render.
const loginWithEmailAndPasswordHandler = async (
  store: MiddlewareAPI,
  action: LoginWithEmailPasswordAction,
) => {
  const { email, password } = action.payload;

  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (error) {
    console.error(error);

    // TODO: show error message according to code
    // const errorCode: string = error.code;

    store.dispatch(loginFailed(UserLoginError.WrongPassword));
  }
};

const logoutHandler = async () => {
  await firebase.auth().signOut();

  history.push('/login');
};

// Called on every successful login.
const loginSuccessfulHandler = (store: MiddlewareAPI) => {
  // Call connect method
  store.dispatch(apiUserConnect());
};

const connectSuccessHandler = () => {
  // Navigating the user to the right place.
  let redirect = getUrlParameter('redirect');
  if (redirect === null) {
    redirect = '/sensory';
  }
  history.push(redirect);
};

let firebaseAuthChangeUnsubscribe: firebase.Unsubscribe;

const userMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: UserActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case SilentLogin: {
      firebaseAuthChangeUnsubscribe = firebase
        .auth()
        .onAuthStateChanged(onAuthStateChanged(store));
      break;
    }
    case LoginWithEmailPassword: {
      await loginWithEmailAndPasswordHandler(
        store,
        action as LoginWithEmailPasswordAction,
      );
      break;
    }
    case LoginSuccessful: {
      loginSuccessfulHandler(store);
      break;
    }
    case APIUserConnect.SUCCESS: {
      connectSuccessHandler();
      break;
    }
    case Logout: {
      firebaseAuthChangeUnsubscribe();

      await logoutHandler();
      break;
    }
    default:
      break;
  }
};

export default userMiddleware;
