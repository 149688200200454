import { withPrefix } from '../utils';
import { GranulometryAnalysis } from '../../models/granulometry';

const Name = 'Granulometry';

export const GetGranulometryAnalysis = withPrefix(
  Name,
  'GetGranulometryAnalysis',
);
export const UpdateGranulometryAnalysis = withPrefix(
  Name,
  'UpdateGranulometryAnalysis',
);
export const CreateGranulometryAnalysis = withPrefix(
  Name,
  'CreateGranulometryAnalysis',
);

// Actions types
export interface GetGranulometryAnalysisAction {
  type: typeof GetGranulometryAnalysis;
  payload: { companyCode: string; userId: string };
}

export interface UpdateGranulometryAnalysisAction {
  type: typeof UpdateGranulometryAnalysis;
  payload: {
    id: string;
    companyCode: string;
    mesh1: number;
    mesh2: number;
    mesh3: number;
    mesh4: number;
    mesh5: number;
    mesh6: number;
    mesh7: number;
    final: number;
    totalWeight: number;
  };
}

export interface CreateGranulometryAnalysisAction {
  type: typeof CreateGranulometryAnalysis;
  payload: {
    companyCode: string;
    mesh1: number;
    mesh2: number;
    mesh3: number;
    mesh4: number;
    mesh5: number;
    mesh6: number;
    mesh7: number;
    final: number;
    totalWeight: number;
  };
}

export type GranulometryActionTypes =
  | GetGranulometryAnalysisAction
  | UpdateGranulometryAnalysisAction
  | CreateGranulometryAnalysisAction;

export type GranulometryState = {
  companyCode: string | null;
  userId: string | null;
  currentGranulometryAnalysis: GranulometryAnalysis | null;
  loadingCurrentGranulometry: boolean;
  savingCurrentGranulometry: boolean;
};
