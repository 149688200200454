import { withPrefix } from '../utils';

const Name = 'Master';

export const ChangeSelectedOrganization = withPrefix(
  Name,
  'ChangeSelectedOrganization',
);

// Actions types
export interface ChangeSelectedOrganizationAction {
  type: typeof ChangeSelectedOrganization;
  payload: { orgId: string };
}

export type MasterActionTypes = ChangeSelectedOrganizationAction;

// Reducer state
export type MasterSettings = {
  selectedOrganizationId: string;
};
