import { Dispatch, MiddlewareAPI } from 'redux';
import { APIActionTypes, APIRequest, BaseUrl } from './types';
import { AppState } from '../utils';
import { showToast } from '../toasts/actions';
import { generateUUID } from '../../utils/string';
import langManager from '../../languages/LanguagesManager';
import { ToastType } from '../../components/toasts/Toast';
import { logout } from '../user/actions';
import { isProduction } from '../../utils/config';

const apiMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: APIActionTypes,
) => {
  next(action);

  const { url, meta, baseAction, payload, type } = action;

  if (type !== APIRequest) {
    return;
  }

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Content-Type', 'application/json');

  const idToken = (store.getState() as AppState).user.token;
  if (idToken) {
    requestHeaders.set('authorization', `Bearer ${idToken}`);
  }

  const configs: RequestInit = {
    headers: requestHeaders,
    method: meta.method,
  };

  if (meta.method === 'PUT' || meta.method === 'POST') {
    if (meta.body) {
      configs.body = JSON.stringify(meta.body);
    }
  }

  // trigger PENDING
  store.dispatch({
    type: baseAction.PENDING,
    payload,
  });

  let fetchUrl = `${BaseUrl.production}${url}`;

  if (!isProduction()) {
    if (process.env.REACT_APP_GCP_PROJECT === 'dlab-dev') {
      fetchUrl = `${BaseUrl.development}${url}`;
    } else if (!process.env.REACT_APP_GCP_PROJECT) {
      fetchUrl = `${BaseUrl.localhost}${url}`;
    }
  }

  try {
    // Getting response from the server
    const response = await fetch(fetchUrl, configs);

    // Parse the response to JSON format
    const { data } = await response.json();

    // Check for errors
    if (!response.ok) {
      if (response.status === 403) {
        store.dispatch(logout());
        return;
      }

      store.dispatch(
        showToast(
          generateUUID(),
          langManager.getStr('errors.somethingWentWrong'),
          ToastType.Danger,
        ),
      );

      store.dispatch({
        type: baseAction.FAILURE,
        payload,
      });
    } else {
      store.dispatch({
        type: baseAction.SUCCESS,
        payload: data,
        requestPayload: payload,
      });
    }
  } catch (error) {
    store.dispatch(
      showToast(
        generateUUID(),
        langManager.getStr('errors.somethingWentWrong'),
        ToastType.Danger,
      ),
    );

    store.dispatch({
      type: baseAction.FAILURE,
      payload,
    });
  }
};

export default apiMiddleware;
