import { withPrefix } from '../utils';

const Name = 'Export';

export const ExportToExcel = withPrefix(Name, 'ExportToExcel');

export interface ExportToExcelAction {
  type: typeof ExportToExcel;
  payload: {};
}

export type ExportActionTypes = ExportToExcelAction;

export type ExportState = {
  excelDate: any;
  exporting: boolean;
  exportError: string | null;
};
