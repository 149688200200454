import { HideToast, ShowToast, ToastsActionTypes } from './types';
import { ToastType } from '../../components/toasts/Toast';

export function showToast(
  id: string,
  message: string,
  type: ToastType,
): ToastsActionTypes {
  return {
    type: ShowToast,
    payload: { id, message, type },
  };
}

export function hideToast(id: string): ToastsActionTypes {
  return {
    type: HideToast,
    payload: { id },
  };
}
