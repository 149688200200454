import {
  LoginFailed,
  LoginFailedAction,
  LoginSuccessful,
  LoginSuccessfulAction,
  LoginWithEmailPassword,
  Logout,
  UserActionTypes,
  UserState,
} from './types';
import { APIActionTypes, APIUserConnect } from '../api/types';

const initialState: UserState = {
  id: null,
  email: null,
  firstName: null,
  lastName: null,
  photoUrl: null,
  token: null,
  errors: {
    login: null,
  },
  loginInProgress: false,
  roles: [],
  organizationId: null,
};

function reducer(
  state: UserState,
  action: UserActionTypes | APIActionTypes,
): UserState {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case LoginWithEmailPassword: {
      return {
        ...state,
        errors: {
          ...state.errors,
          login: null,
        },
        loginInProgress: true,
      };
    }
    case LoginSuccessful: {
      const { payload } = action as LoginSuccessfulAction;
      const { token, id, email, photoUrl } = payload;

      return {
        ...state,
        token,
        id,
        email,
        photoUrl,
      };
    }
    case LoginFailed: {
      const { payload } = action as LoginFailedAction;

      return {
        ...state,
        errors: {
          ...state.errors,
          login: payload.reason,
        },
        loginInProgress: false,
      };
    }
    case Logout: {
      return initialState;
    }
    case APIUserConnect.SUCCESS: {
      const { roles, orgId, firstName, lastName } = action.payload;

      return {
        ...state,
        roles,
        firstName,
        lastName,
        organizationId: orgId,
        loginInProgress: false,
      };
    }
    case APIUserConnect.FAILURE: {
      return {
        ...state,
        loginInProgress: false,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
