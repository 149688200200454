import { withPrefix } from '../utils';

// Server urls
export const BaseUrl = {
  localhost: 'http://localhost:8085',
  // localhost: 'https://dev-dlab-api.demetria.ag',
  development: 'https://dev-dlab-api.demetria.ag',
  production: 'https://dlab-api.demetria.ag',
};

// Generic action type for API calls
// Dispatching pending before sending the request and then
// success or failure, according to the results (status code !== 200 => FAILURE)
const createAPIRequestAction = (actionType: string) => ({
  PENDING: `${actionType}_PENDING`,
  SUCCESS: `${actionType}_SUCCESS`,
  FAILURE: `${actionType}_FAILURE`,
  BASE: actionType,
});

const Name = 'API';

export const APIRequest = withPrefix(Name, 'Request');

// Authentication
export const APIUserConnect = createAPIRequestAction(
  withPrefix(Name, 'APIUserConnect'),
);

// Sensory
export const APIGetSensoryAnalysesForUser = createAPIRequestAction(
  withPrefix(Name, 'APIGetSensoryAnalysesForUser'),
);
export const APIGetSensoryAnalysesForOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIGetSensoryAnalysesForOrganization'),
);
export const APIGetSensoryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIGetSensoryAnalysis'),
);
export const APIUpdateSensoryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIUpdateSensoryAnalysis'),
);
export const APICreateSensoryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APICreateSensoryAnalysis'),
);
export const APIDeleteSensoryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIDeleteSensoryAnalysis'),
);

// Organizations
export const APIGetOrganizations = createAPIRequestAction(
  withPrefix(Name, 'APIGetOrganizations'),
);
export const APIGetOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIGetOrganization'),
);
export const APICreateOrganization = createAPIRequestAction(
  withPrefix(Name, 'APICreateOrganization'),
);
export const APIUpdateOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIUpdateOrganization'),
);
export const APIGetOrganizationUsers = createAPIRequestAction(
  withPrefix(Name, 'APIGetOrganizationUsers'),
);
export const APIAddUserToOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIAddUserToOrganization'),
);
export const APIUpdateUserInOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIUpdateUserInOrganization'),
);
export const APIRemoveUserFromOrganization = createAPIRequestAction(
  withPrefix(Name, 'APIRemoveUserFromOrganization'),
);

// Defects
export const APIGetDefectsAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIGetDefectsAnalysis'),
);

export const APICreateDefectsAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APICreateDefectsAnalysis'),
);

export const APIUpdateDefectsAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIUpdateDefectsAnalysis'),
);

// Granulometry
export const APIGetGranulometryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIGetGranulometryAnalysis'),
);

export const APICreateGranulometryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APICreateGranulometryAnalysis'),
);

export const APIUpdateGranulometryAnalysis = createAPIRequestAction(
  withPrefix(Name, 'APIUpdateGranulometryAnalysis'),
);

// Export
export const APIExportDataToExcel = createAPIRequestAction(
  withPrefix(Name, 'APIExportDataToExcel'),
);

// Actions types
export interface APIUserConnectAction {
  type: typeof APIRequest;
  payload: {};
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIUserConnect;
}

export interface APIGetSensoryAnalysesForUserAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetSensoryAnalysesForUser;
}

export interface APIGetSensoryAnalysesForOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetSensoryAnalysesForOrganization;
}

export interface APIGetSensoryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetSensoryAnalysis;
}

export interface APIUpdateSensoryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'PUT';
    body: any;
  };
  baseAction: typeof APIUpdateSensoryAnalysis;
}

export interface APICreateSensoryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'POST';
    body: any;
  };
  baseAction: typeof APICreateSensoryAnalysis;
}

export interface APIDeleteSensoryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'DELETE';
  };
  baseAction: typeof APIDeleteSensoryAnalysis;
}

export interface APIGetOrganizationsAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetOrganizations;
}

export interface APIGetOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetOrganization;
}

export interface APICreateOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'POST';
    body: any;
  };
  baseAction: typeof APICreateOrganization;
}

export interface APIUpdateOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'PUT';
    body: any;
  };
  baseAction: typeof APIUpdateOrganization;
}

export interface APIGetOrganizationUsersAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetOrganizationUsers;
  requestPayload: any;
}

export interface APIAddUserToOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'POST';
    body: any;
  };
  baseAction: typeof APIAddUserToOrganization;
  requestPayload: any;
}

export interface APIUpdateUserInOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'PUT';
    body: any;
  };
  baseAction: typeof APIUpdateUserInOrganization;
  requestPayload: any;
}

export interface APIRemoveUserFromOrganizationAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'DELETE';
  };
  baseAction: typeof APIRemoveUserFromOrganization;
  requestPayload: any;
}

export interface APIGetDefectsAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetDefectsAnalysis;
}

export interface APICreateDefectsAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'POST';
    body: any;
  };
  baseAction: typeof APICreateDefectsAnalysis;
}

export interface APIUpdateDefectsAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'PUT';
    body: any;
  };
  baseAction: typeof APIUpdateDefectsAnalysis;
}

export interface APIGetGranulometryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIGetGranulometryAnalysis;
}

export interface APICreateGranulometryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'POST';
    body: any;
  };
  baseAction: typeof APICreateGranulometryAnalysis;
}

export interface APIUpdateGranulometryAnalysisAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'PUT';
    body: any;
  };
  baseAction: typeof APIUpdateGranulometryAnalysis;
}

export interface APIExportDataToExcelAction {
  type: typeof APIRequest;
  payload: any;
  url: string;
  meta: {
    method: 'GET';
  };
  baseAction: typeof APIExportDataToExcel;
}

export type APIActionTypes =
  | APIUserConnectAction
  | APIGetSensoryAnalysesForUserAction
  | APIGetSensoryAnalysisAction
  | APIUpdateSensoryAnalysisAction
  | APICreateSensoryAnalysisAction
  | APIDeleteSensoryAnalysisAction
  | APIGetOrganizationsAction
  | APIGetOrganizationAction
  | APICreateOrganizationAction
  | APIUpdateOrganizationAction
  | APIGetOrganizationUsersAction
  | APIAddUserToOrganizationAction
  | APIUpdateUserInOrganizationAction
  | APIRemoveUserFromOrganizationAction
  | APIGetDefectsAnalysisAction
  | APICreateDefectsAnalysisAction
  | APIUpdateDefectsAnalysisAction
  | APIGetGranulometryAnalysisAction
  | APICreateGranulometryAnalysisAction
  | APIUpdateGranulometryAnalysisAction
  | APIExportDataToExcelAction;
