import { Language } from '../../languages/LanguagesManager';
import { ChangeLanguage, SettingsActionTypes, SettingsState } from './types';

const initialState: SettingsState = {
  language: Language.Spanish,
};

function reducer(
  state: SettingsState,
  action: SettingsActionTypes,
): SettingsState {
  if (!state) {
    return initialState;
  }

  const { type, payload } = action;

  switch (type) {
    case ChangeLanguage: {
      const { language } = payload;

      return {
        ...state,
        language,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
