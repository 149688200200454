import { createSelector } from 'reselect';
import { AppState } from '../utils';
import { User, UserRole } from '../../models/user';

const getUserTokenSelector = (state: AppState) => state.user.token;
const getLoginErrorSelector = (state: AppState) => state.user.errors.login;
const getUserEmailSelector = (state: AppState) => state.user.email;
const getIsLoginInProgressSelector = (state: AppState) =>
  state.user.loginInProgress;
const getUserRolesSelector = (state: AppState) => state.user.roles;
const getUserIdSelector = (state: AppState) => state.user.id;
const getUserOrganizationId = (state: AppState) => state.user.organizationId;
const getUserFirstName = (state: AppState) => state.user.firstName;
const getUserLastName = (state: AppState) => state.user.lastName;

export const isUserLoggedIn = createSelector(
  [getUserTokenSelector],
  (token) => token !== null && token.length > 0,
);

export const getLoginError = createSelector(
  [getLoginErrorSelector],
  (loginError) => loginError,
);

export const getCurrentUserDisplayName = createSelector(
  [getUserFirstName, getUserLastName, getUserEmailSelector],
  (firstName, lastName, email) => {
    let displayName = email || '';
    if (firstName?.length !== 0 && lastName?.length !== 0) {
      displayName = `${firstName} ${lastName}`;
    }
    return displayName;
  },
);

export const isLoginInProgress = createSelector(
  [getIsLoginInProgressSelector],
  (loginInProgress) => loginInProgress,
);

export const isMasterUser = createSelector([getUserRolesSelector], (roles) =>
  roles.includes(UserRole.Master),
);

export const isOrgManager = createSelector(
  [getUserRolesSelector],
  (roles) =>
    roles.includes(UserRole.OrgManager) || roles.includes(UserRole.Master),
);

export const getAuthUser = createSelector(
  [
    getUserIdSelector,
    getUserOrganizationId,
    getUserEmailSelector,
    getUserFirstName,
    getUserLastName,
    getUserRolesSelector,
  ],
  (userId, orgId, email, firstName, lastName, roles): User | null => {
    if (userId === null || orgId === null || email === null) {
      return null;
    }

    return {
      id: userId,
      organizationId: orgId,
      email,
      firstName: firstName || '',
      lastName: lastName || '',
      roles,
    };
  },
);
