import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import { isUserLoggedIn } from '../../store/user/selector';

function ProtectedRoute(props: InferProps<typeof ProtectedRoute.propTypes>) {
  const pathname: string = useLocation().pathname;

  const isLoggedIn = useSelector(isUserLoggedIn);

  const Component = props.component;

  return (
    <Route
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', search: `?redirect=${pathname}` }}
          />
        )
      }
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  exact: false,
};

export default ProtectedRoute;
