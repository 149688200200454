import { MiddlewareAPI, Dispatch } from 'redux';
import {
  ChangeLanguage,
  ChangeLanguageAction,
  SettingsActionTypes,
} from './types';
import langManager from '../../languages/LanguagesManager';

// Change app language.
// Every component which is bind to the store, will get re-render.
const updateLanguage = (store: MiddlewareAPI, action: ChangeLanguageAction) => {
  const { language } = action.payload;
  langManager.setCurrentLanguage(language);
};

const settingsMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: SettingsActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case ChangeLanguage:
      updateLanguage(store, action);
      break;
    default:
      break;
  }
};

export default settingsMiddleware;
