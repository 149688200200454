import {
  CreateGranulometryAnalysis,
  GetGranulometryAnalysis,
  GranulometryActionTypes,
  GranulometryState,
  UpdateGranulometryAnalysis,
} from './types';
import {
  APIActionTypes,
  APICreateDefectsAnalysis,
  APICreateGranulometryAnalysis,
  APIGetGranulometryAnalysis,
  APIUpdateDefectsAnalysis,
  APIUpdateGranulometryAnalysis,
} from '../api/types';

const initialState: GranulometryState = {
  companyCode: null,
  userId: null,
  currentGranulometryAnalysis: null,
  loadingCurrentGranulometry: false,
  savingCurrentGranulometry: false,
};

function reducer(
  state: GranulometryState,
  action: GranulometryActionTypes | APIActionTypes,
) {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case GetGranulometryAnalysis: {
      const { companyCode, userId } = action.payload;

      return {
        ...state,
        companyCode,
        userId,
        currentGranulometryAnalysis: null,
        loadingCurrentGranulometry: true,
      };
    }
    case APIGetGranulometryAnalysis.SUCCESS: {
      const data = action.payload;

      if (!data) {
        return {
          ...state,
          loadingCurrentGranulometry: false,
        };
      }

      return {
        ...state,
        loadingCurrentGranulometry: false,
        currentGranulometryAnalysis: {
          id: data.id,
          companyCode: data.companyCode,
          createdAt: data.createdAt,
          mesh1: data.mesh1,
          mesh2: data.mesh2,
          mesh3: data.mesh3,
          mesh4: data.mesh4,
          mesh5: data.mesh5,
          mesh6: data.mesh6,
          mesh7: data.mesh7,
          final: data.final,
          totalWeight: data.totalWeight,
        },
      };
    }
    case APIGetGranulometryAnalysis.FAILURE: {
      return {
        ...state,
        loadingCurrentGranulometry: false,
      };
    }
    case CreateGranulometryAnalysis:
    case UpdateGranulometryAnalysis: {
      return {
        ...state,
        savingCurrentGranulometry: true,
      };
    }
    case APICreateGranulometryAnalysis.SUCCESS:
    case APIUpdateGranulometryAnalysis.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        savingCurrentGranulometry: false,
        currentDefectsAnalysis: {
          id: data.id,
          companyCode: data.companyCode,
          createdAt: data.createdAt,
          mesh1: data.mesh1,
          mesh2: data.mesh2,
          mesh3: data.mesh3,
          mesh4: data.mesh4,
          mesh5: data.mesh5,
          mesh6: data.mesh6,
          mesh7: data.mesh7,
          final: data.final,
          totalWeight: data.totalWeight,
        },
      };
    }
    case APICreateDefectsAnalysis.FAILURE:
    case APIUpdateDefectsAnalysis.FAILURE: {
      return {
        ...state,
        savingCurrentGranulometry: false,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
