import { compose } from 'redux';
import { SettingsState } from './settings/types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { SideBarState } from './sidebar/types';
import { UserState } from './user/types';
import { SensoryState } from './sensory/types';
import { OrganizationsState } from './organizations/types';
import { ToastsState } from './toasts/types';
import { DefectsState } from './defects/types';
import { GranulometryState } from './granulometry/types';
import { MasterSettings } from './master/types';

// For debugging
// https://stackoverflow.com/questions/52800877/has-anyone-came-across-this-error-in-ts-with-redux-dev-tools-property-redux
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Entire app state
export type AppState = {
  settings: SettingsState;
  sidebar: SideBarState;
  user: UserState;
  sensory: SensoryState;
  organizations: OrganizationsState;
  toasts: ToastsState;
  defects: DefectsState;
  granulometry: GranulometryState;
  master: MasterSettings;
};

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;

export const withPrefix = (name: string, action: string): string =>
  `${name}.${action}`;
