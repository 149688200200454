import {
  LoginFailed,
  LoginSuccessful,
  LoginWithEmailPassword,
  Logout,
  SilentLogin,
  UserActionTypes,
} from './types';

export function loginWithEmailAndPassword(
  email: string,
  password: string,
): UserActionTypes {
  return {
    type: LoginWithEmailPassword,
    payload: { email, password },
  };
}

export function silentLogin(): UserActionTypes {
  return {
    type: SilentLogin,
    payload: {},
  };
}

export function loginSuccessful(
  token: string,
  id: string,
  name: string,
  email: string,
  photoUrl: string,
): UserActionTypes {
  return {
    type: LoginSuccessful,
    payload: { token, id, name, email, photoUrl },
  };
}

export function loginFailed(reason: string): UserActionTypes {
  return {
    type: LoginFailed,
    payload: { reason },
  };
}

export function logout(): UserActionTypes {
  return {
    type: Logout,
    payload: {},
  };
}
