import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import './services/firebase/firebase';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import BugsnagProvider from './services/bugsnag/BugsnagProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import { store, persistor } from './store/store';

React.icons = icons;

ReactDOM.render(
  <React.StrictMode>
    <BugsnagProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BugsnagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
