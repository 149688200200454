import { withPrefix } from '../utils';
import { Organization } from '../../models/organization';
import { ProductType } from '../../models/product';
import { User, UserRole } from '../../models/user';

const Name = 'Organizations';

export const GetOrganizations = withPrefix(Name, 'GetOrganizations');
export const GetOrganization = withPrefix(Name, 'GetOrganization');
export const CreateOrganization = withPrefix(Name, 'CreateOrganization');
export const UpdateOrganization = withPrefix(Name, 'UpdateOrganization');
export const GetOrganizationUsers = withPrefix(Name, 'GetOrganizationUsers');
export const AddUserToOrganization = withPrefix(Name, 'AddUserToOrganization');
export const UpdateUserInOrganization = withPrefix(
  Name,
  'UpdateUserInOrganization',
);
export const RemoveUserFromOrganization = withPrefix(
  Name,
  'RemoveUserFromOrganization',
);

// Actions types
export interface GetOrganizationsAction {
  type: typeof GetOrganizations;
  payload: {};
}

export interface GetOrganizationAction {
  type: typeof GetOrganization;
  payload: { id: string };
}

export interface CreateOrganizationAction {
  type: typeof CreateOrganization;
  payload: { name: string; products: Array<ProductType> };
}

export interface UpdateOrganizationAction {
  type: typeof UpdateOrganization;
  payload: { id: string; name: string; products: Array<ProductType> };
}

export interface GetOrganizationUsersAction {
  type: typeof GetOrganizationUsers;
  payload: { id: string };
}

export interface AddUserToOrganizationAction {
  type: typeof AddUserToOrganization;
  payload: {
    orgId: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    roles: Array<UserRole>;
  };
}

export interface UpdateUserInOrganizationAction {
  type: typeof UpdateUserInOrganization;
  payload: {
    orgId: string;
    userId: string;
    firstName: string;
    lastName: string;
    roles: Array<UserRole>;
  };
}

export interface RemoveUserFromOrganizationAction {
  type: typeof RemoveUserFromOrganization;
  payload: {
    orgId: string;
    userId: string;
  };
}

export type OrganizationsActionTypes =
  | GetOrganizationsAction
  | GetOrganizationAction
  | CreateOrganizationAction
  | UpdateOrganizationAction
  | GetOrganizationUsersAction
  | AddUserToOrganizationAction
  | UpdateUserInOrganizationAction
  | RemoveUserFromOrganizationAction;

// Reducer state
export type OrganizationsState = {
  organizationsIds: Array<string>;
  organizations: { [key: string]: Organization };
  organizationsUsers: { [key: string]: { [key: string]: User } };
  loadingOrganizations: boolean;
  loadingCurrentOrganization: boolean;
  loadingCurrentOrganizationUsers: boolean;
  savingCurrentOrganization: boolean;
  savingUser: boolean;
};
