import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loading from './components/loading/Loading';
import CustomBrowserRouter from './components/router/CustomBrowserRouter';
import ProtectedRoute from './components/router/ProtectedRoute';
import ToastsContainer from './components/toasts/ToastsContainer';
import './scss/style.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/login/Login'));

function App() {
  return (
    <CustomBrowserRouter>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute path="/" component={TheLayout} />
        </Switch>
      </React.Suspense>
      <ToastsContainer />
    </CustomBrowserRouter>
  );
}

export default App;

// TODO: Remove all prop-types usage
