import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

type BugsnagProviderProps = {
  children: React.ReactNode;
};

function BugsnagProvider(props: BugsnagProviderProps) {
  Bugsnag.start({
    apiKey: '5d4a74f89ae16b10a580ff9ddcb8a090',
    plugins: [new BugsnagPluginReact()],
  });

  const reactPlugin = Bugsnag.getPlugin('react');
  if (reactPlugin) {
    const ErrorBoundary = reactPlugin.createErrorBoundary(React);

    return <ErrorBoundary>{props.children}</ErrorBoundary>;
  }

  return null;
}

export default BugsnagProvider;
