export enum UserRole {
  Master = 'Master',
  OrgManager = 'OrgManager',
  SensoryAnalysisCollector = 'SensoryAnalysisCollector',
  DefectsCollector = 'DefectsCollector',
  GranulometryCollector = 'GranulometryCollector',
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<UserRole>;
  organizationId: string;
}

export function getDisplayName(user: User): string {
  if (user.firstName?.length > 0 && user.lastName?.length > 0) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.email;
}

export function getInitials(user: User): string {
  if (user.firstName?.length > 0 && user.lastName?.length > 0) {
    return `${user.firstName.substr(0, 1)}${user.lastName.substr(
      0,
      1,
    )}`.toUpperCase();
  }
  return user.email.substr(0, user.email.indexOf('@')).toUpperCase();
}
