import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { InferProps } from 'prop-types';
import ScrollToTop from './ScrollToTop';
import { ReactChildren } from '../../utils/types';

export const history = createBrowserHistory();

function CustomBrowserRouter(
  props: InferProps<typeof CustomBrowserRouter.propTypes>,
) {
  return (
    <Router history={history}>
      <ScrollToTop />
      {props.children}
    </Router>
  );
}

CustomBrowserRouter.propTypes = {
  children: ReactChildren.isRequired,
};

export default CustomBrowserRouter;
