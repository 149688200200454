import { get } from 'lodash';
import { Component } from 'react';
import Locale_en from './en.json';
import Locale_es from './es.json';
import { isNullOrEmpty, replaceAll } from '../utils/string';

export enum Language {
  English = 'English',
  Spanish = 'Spanish',
}

export class LanguagesManager {
  currentLanguageKey: Language;
  currentLanguage: any;

  constructor() {
    this.currentLanguageKey = Language.Spanish;
    this.currentLanguage = Locale_es;
  }

  setCurrentLanguage = (languageStr: Language): void => {
    switch (languageStr) {
      case Language.English:
        this.currentLanguageKey = Language.English;
        this.currentLanguage = Locale_en;
        break;
      case Language.Spanish:
        this.currentLanguageKey = Language.Spanish;
        this.currentLanguage = Locale_es;
        break;
      default:
        this.currentLanguageKey = Language.Spanish;
        this.currentLanguage = Locale_es;
        break;
    }
  };

  getCurrentLanguage = (): Language => {
    return this.currentLanguageKey;
  };

  getStr = (key: string): string => {
    let possibleStr = get(this.currentLanguage, key);
    if (isNullOrEmpty(possibleStr)) {
      return key;
    }
    return possibleStr;
  };

  fill = (key: string, params: Map<string, string>) => {
    let localeStrClone = this.getStr(key);
    params.forEach((value: string, key: string) => {
      localeStrClone = replaceAll(`^${key}^`, value, localeStrClone);
    });
    return localeStrClone;
  };

  fillJsx = (key: string, componentsParams: Map<string, Component>) => {
    let str = this.getStr(key);
    const resultParts = [];
    const langKeys = Object.keys(componentsParams);
    for (let i = 0; i < langKeys.length; i += 1) {
      const componentKey = langKeys[i];
      const tempParts = str.split(`^${componentKey}^`);
      if (tempParts.length >= 2) {
        resultParts.push(tempParts[0]);
        resultParts.push(componentsParams.get(componentKey));
        if (i === langKeys.length - 1) {
          resultParts.push(tempParts[1]);
        }
      }
      str = tempParts[1];
    }
    return resultParts;
  };
}

// Language Initialization
// Global access variable
const langManager = new LanguagesManager();

export default langManager;
