import { Dispatch, MiddlewareAPI } from 'redux';
import {
  CreateDefectsAnalysis,
  CreateDefectsAnalysisAction,
  DefectsActionTypes,
  GetDefectsAnalysis,
  GetDefectsAnalysisAction,
  UpdateDefectsAnalysis,
  UpdateDefectsAnalysisAction,
} from './types';
import {
  apiCreateDefectsAnalysis,
  apiGetDefectsAnalysis,
  apiUpdateDefectsAnalysis,
} from '../api/actions';
import {
  APICreateDefectsAnalysis,
  APIUpdateDefectsAnalysis,
} from '../api/types';
import { showToast } from '../toasts/actions';
import { generateUUID } from '../../utils/string';
import langManager from '../../languages/LanguagesManager';
import { ToastType } from '../../components/toasts/Toast';

const getDefectsAnalysisHandler = (
  store: MiddlewareAPI,
  action: GetDefectsAnalysisAction,
) => {
  store.dispatch(
    apiGetDefectsAnalysis(action.payload.companyCode, action.payload.userId),
  );
};

const createDefectsAnalysisHandler = (
  store: MiddlewareAPI,
  action: CreateDefectsAnalysisAction,
) => {
  const payload = action.payload;
  store.dispatch(
    apiCreateDefectsAnalysis(
      payload.companyCode,
      payload.black,
      payload.crystal,
      payload.cutOrNipped,
      payload.fadedAmber,
      payload.fadedOldish,
      payload.fadedOverDried,
      payload.fadedStreaked,
      payload.immature,
      payload.insectDamage,
      payload.moldy,
      payload.pressedOrCrushed,
      payload.shrunk,
      payload.sour,
      payload.wetOrUnderDried,
      payload.totalWeight,
    ),
  );
};

const updateDefectsAnalysisHandler = (
  store: MiddlewareAPI,
  action: UpdateDefectsAnalysisAction,
) => {
  const payload = action.payload;
  store.dispatch(
    apiUpdateDefectsAnalysis(
      payload.id,
      payload.companyCode,
      payload.black,
      payload.crystal,
      payload.cutOrNipped,
      payload.fadedAmber,
      payload.fadedOldish,
      payload.fadedOverDried,
      payload.fadedStreaked,
      payload.immature,
      payload.insectDamage,
      payload.moldy,
      payload.pressedOrCrushed,
      payload.shrunk,
      payload.sour,
      payload.wetOrUnderDried,
      payload.totalWeight,
    ),
  );
};

const updateDefectsAnalysisCompletedHandler = (store: MiddlewareAPI) => {
  store.dispatch(
    showToast(
      generateUUID(),
      langManager.getStr('common.savedSuccess'),
      ToastType.Success,
    ),
  );
};

const defectsMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: DefectsActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case GetDefectsAnalysis: {
      getDefectsAnalysisHandler(store, action as GetDefectsAnalysisAction);
      break;
    }
    case CreateDefectsAnalysis: {
      createDefectsAnalysisHandler(
        store,
        action as CreateDefectsAnalysisAction,
      );
      break;
    }
    case UpdateDefectsAnalysis: {
      updateDefectsAnalysisHandler(
        store,
        action as UpdateDefectsAnalysisAction,
      );
      break;
    }
    case APICreateDefectsAnalysis.SUCCESS:
    case APIUpdateDefectsAnalysis.SUCCESS: {
      updateDefectsAnalysisCompletedHandler(store);
      break;
    }
    default:
      break;
  }
};

export default defectsMiddleware;
