import { Dispatch, MiddlewareAPI } from 'redux';
import { ChangeSelectedOrganization, MasterActionTypes } from './types';
import { getSensoryAnalysesForOrg } from '../sensory/actions';
import { history } from '../../components/router/CustomBrowserRouter';

const masterMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => async (
  action: MasterActionTypes,
) => {
  next(action);

  const { type } = action;

  switch (type) {
    case ChangeSelectedOrganization: {
      const { orgId } = action.payload;
      store.dispatch(getSensoryAnalysesForOrg(orgId));
      history.replace('/sensory');
      break;
    }
    default:
      break;
  }
};

export default masterMiddleware;
