import { withPrefix } from '../utils';
import { Defect, DefectsAnalysis } from '../../models/defects';

const Name = 'Defects';

export const GetDefectsAnalysis = withPrefix(Name, 'GetDefectsAnalysis');
export const UpdateDefectsAnalysis = withPrefix(Name, 'UpdateDefectsAnalysis');
export const CreateDefectsAnalysis = withPrefix(Name, 'CreateDefectsAnalysis');

// Actions types
export interface GetDefectsAnalysisAction {
  type: typeof GetDefectsAnalysis;
  payload: { companyCode: string; userId: string };
}

export interface UpdateDefectsAnalysisAction {
  type: typeof UpdateDefectsAnalysis;
  payload: {
    id: string;
    companyCode: string;
    black: Defect;
    crystal: Defect;
    cutOrNipped: Defect;
    fadedAmber: Defect;
    fadedOldish: Defect;
    fadedOverDried: Defect;
    fadedStreaked: Defect;
    immature: Defect;
    insectDamage: Defect;
    moldy: Defect;
    pressedOrCrushed: Defect;
    shrunk: Defect;
    sour: Defect;
    wetOrUnderDried: Defect;
    totalWeight: number;
  };
}

export interface CreateDefectsAnalysisAction {
  type: typeof CreateDefectsAnalysis;
  payload: {
    companyCode: string;
    black: Defect;
    crystal: Defect;
    cutOrNipped: Defect;
    fadedAmber: Defect;
    fadedOldish: Defect;
    fadedOverDried: Defect;
    fadedStreaked: Defect;
    immature: Defect;
    insectDamage: Defect;
    moldy: Defect;
    pressedOrCrushed: Defect;
    shrunk: Defect;
    sour: Defect;
    wetOrUnderDried: Defect;
    totalWeight: number;
  };
}

export type DefectsActionTypes =
  | GetDefectsAnalysisAction
  | CreateDefectsAnalysisAction
  | UpdateDefectsAnalysisAction;

export type DefectsState = {
  companyCode: string | null;
  userId: string | null;
  currentDefectsAnalysis: DefectsAnalysis | null;
  loadingCurrentDefects: boolean;
  savingCurrentDefects: boolean;
};
