import {
  CreateDefectsAnalysis,
  DefectsActionTypes,
  DefectsState,
  GetDefectsAnalysis,
  UpdateDefectsAnalysis,
} from './types';
import {
  APIActionTypes,
  APICreateDefectsAnalysis,
  APIGetDefectsAnalysis,
  APIUpdateDefectsAnalysis,
} from '../api/types';

const initialState: DefectsState = {
  companyCode: null,
  userId: null,
  currentDefectsAnalysis: null,
  loadingCurrentDefects: false,
  savingCurrentDefects: false,
};

function reducer(
  state: DefectsState,
  action: DefectsActionTypes | APIActionTypes,
) {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case GetDefectsAnalysis: {
      const { companyCode, userId } = action.payload;

      return {
        ...state,
        companyCode,
        userId,
        currentDefectsAnalysis: null,
        loadingCurrentDefects: true,
      };
    }
    case APIGetDefectsAnalysis.SUCCESS: {
      const data = action.payload;

      if (!data) {
        return {
          ...state,
          loadingCurrentDefects: false,
        };
      }

      return {
        ...state,
        loadingCurrentDefects: false,
        currentDefectsAnalysis: {
          id: data.id,
          companyCode: data.companyCode,
          createdAt: data.createdAt,
          black: data.black,
          crystal: data.crystal,
          cutOrNipped: data.cutOrNipped,
          fadedAmber: data.fadedAmber,
          fadedOldish: data.fadedOldish,
          fadedOverDried: data.fadedOverDried,
          fadedStreaked: data.fadedStreaked,
          immature: data.immature,
          insectDamage: data.insectDamage,
          moldy: data.moldy,
          pressedOrCrushed: data.pressedOrCrushed,
          shrunk: data.shrunk,
          sour: data.sour,
          wetOrUnderDried: data.wetOrUnderDried,
          totalWeight: data.totalWeight,
        },
      };
    }
    case APIGetDefectsAnalysis.FAILURE: {
      return {
        ...state,
        loadingCurrentDefects: false,
      };
    }
    case CreateDefectsAnalysis:
    case UpdateDefectsAnalysis: {
      return {
        ...state,
        savingCurrentDefects: true,
      };
    }
    case APICreateDefectsAnalysis.SUCCESS:
    case APIUpdateDefectsAnalysis.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        savingCurrentDefects: false,
        currentDefectsAnalysis: {
          id: data.id,
          companyCode: data.companyCode,
          createdAt: data.createdAt,
          black: data.black,
          crystal: data.crystal,
          cutOrNipped: data.cutOrNipped,
          fadedAmber: data.fadedAmber,
          fadedOldish: data.fadedOldish,
          fadedOverDried: data.fadedOverDried,
          fadedStreaked: data.fadedStreaked,
          immature: data.immature,
          insectDamage: data.insectDamage,
          moldy: data.moldy,
          pressedOrCrushed: data.pressedOrCrushed,
          shrunk: data.shrunk,
          sour: data.sour,
          wetOrUnderDried: data.wetOrUnderDried,
          totalWeight: data.totalWeight,
        },
      };
    }
    case APICreateDefectsAnalysis.FAILURE:
    case APIUpdateDefectsAnalysis.FAILURE: {
      return {
        ...state,
        savingCurrentDefects: false,
      };
    }
    default:
      break;
  }

  return state;
}

export default reducer;
