import {
  CreateSensoryAnalysis,
  GetSensoryAnalyses,
  GetSensoryAnalysesForOrg,
  GetSensoryAnalysis,
  SensoryActionTypes,
  SensoryState,
  UpdateSensoryAnalysis,
} from './types';
import {
  APIActionTypes,
  APIGetSensoryAnalysis,
  APIGetSensoryAnalysesForUser,
  APIUpdateSensoryAnalysis,
  APICreateSensoryAnalysis,
  APIGetSensoryAnalysesForOrganization,
} from '../api/types';
import { SensoryAnalysis } from '../../models/sensory';
import { Logout } from '../user/types';

const initialState: SensoryState = {
  analyses: {},
  analysesIds: [],
  loadingAnalyses: false,
  loadingCurrentAnalysis: false,
  savingCurrentAnalysis: false,
};

function reducer(
  state: SensoryState,
  action: SensoryActionTypes | APIActionTypes,
): SensoryState {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case GetSensoryAnalyses:
    case GetSensoryAnalysesForOrg: {
      return {
        ...state,
        loadingAnalyses: true,
      };
    }
    case APIGetSensoryAnalysesForUser.SUCCESS:
    case APIGetSensoryAnalysesForOrganization.SUCCESS: {
      const { sensory }: { sensory: Array<SensoryAnalysis> } = action.payload;

      const analysesIds = sensory.map((item: SensoryAnalysis) => item.id);

      const analyses = sensory.reduce(function (
        result: { [key: string]: SensoryAnalysis },
        item,
      ) {
        result[item.id] = item;
        return result;
      },
      {});

      return {
        ...state,
        analysesIds,
        analyses,
        loadingAnalyses: false,
      };
    }
    case APIGetSensoryAnalysesForUser.FAILURE:
    case APIGetSensoryAnalysesForOrganization.FAILURE: {
      return {
        ...state,
        loadingAnalyses: false,
      };
    }
    case GetSensoryAnalysis: {
      return {
        ...state,
        loadingCurrentAnalysis: true,
      };
    }
    case APIGetSensoryAnalysis.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        analyses: {
          ...state.analyses,
          [data.id]: data,
        },
        loadingCurrentAnalysis: false,
      };
    }
    case APIGetSensoryAnalysis.FAILURE: {
      return {
        ...state,
        loadingCurrentAnalysis: false,
      };
    }
    case CreateSensoryAnalysis:
    case UpdateSensoryAnalysis: {
      return {
        ...state,
        savingCurrentAnalysis: true,
      };
    }
    case APICreateSensoryAnalysis.SUCCESS:
    case APIUpdateSensoryAnalysis.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        analyses: {
          ...state.analyses,
          [data.id]: data,
        },
        savingCurrentAnalysis: false,
      };
    }
    case APICreateSensoryAnalysis.FAILURE:
    case APIUpdateSensoryAnalysis.FAILURE: {
      return {
        ...state,
        savingCurrentAnalysis: false,
      };
    }
    case Logout: {
      return initialState;
    }
    default:
      break;
  }

  return state;
}

export default reducer;
