export function generateUUID(): string {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function isNullOrEmpty(str: string): boolean {
  return str === undefined || str === null || str.trim() === '';
}

export function replaceAll(find: string, replace: string, str: string): string {
  let newStr = str;
  while (newStr.indexOf(find) >= 0) {
    newStr = newStr.replace(find, replace);
  }
  return newStr;
}

export function repeat(str: string, n: number): string {
  if (n === 0) {
    return '';
  }
  let s = str;
  let i;
  for (i = 1; i < n; i += 1) {
    s += str;
  }
  return s;
}
