import {
  AddUserToOrganization,
  CreateOrganization,
  GetOrganization,
  GetOrganizations,
  GetOrganizationUsers,
  OrganizationsActionTypes,
  OrganizationsState,
  RemoveUserFromOrganization,
  UpdateOrganization,
  UpdateUserInOrganization,
} from './types';
import {
  APIActionTypes,
  APIAddUserToOrganization,
  APIAddUserToOrganizationAction,
  APICreateOrganization,
  APIGetOrganization,
  APIGetOrganizations,
  APIGetOrganizationUsers,
  APIGetOrganizationUsersAction,
  APIUpdateOrganization,
  APIUpdateUserInOrganization,
} from '../api/types';
import { Organization } from '../../models/organization';
import { User } from '../../models/user';
import { Logout } from '../user/types';

const initialState: OrganizationsState = {
  loadingOrganizations: false,
  organizations: {}, // Org id to Organization object
  organizationsIds: [],
  organizationsUsers: {}, // Org id to to object of User id to User object
  loadingCurrentOrganization: false,
  savingCurrentOrganization: false,
  loadingCurrentOrganizationUsers: false,
  savingUser: false,
};

function reducer(
  state: OrganizationsState,
  action: OrganizationsActionTypes | APIActionTypes,
): OrganizationsState {
  if (!state) {
    return initialState;
  }

  const { type } = action;

  switch (type) {
    case GetOrganizations: {
      return {
        ...state,
        loadingOrganizations: true,
      };
    }
    case APIGetOrganizations.SUCCESS: {
      const {
        organizations,
      }: { organizations: Array<Organization> } = action.payload;

      const organizationsIds = organizations.map(
        (item: Organization) => item.id,
      );

      const organizationsById = organizations.reduce(function (
        result: { [key: string]: Organization },
        item,
      ) {
        result[item.id] = item;
        return result;
      },
      {});

      return {
        ...state,
        organizationsIds,
        organizations: organizationsById,
        loadingOrganizations: false,
      };
    }
    case APIGetOrganizations.FAILURE: {
      return {
        ...state,
        loadingOrganizations: false,
      };
    }
    case GetOrganization: {
      return {
        ...state,
        loadingCurrentOrganization: true,
      };
    }
    case APIGetOrganization.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        organizations: {
          ...state.organizations,
          [data.id]: data,
        },
        loadingCurrentOrganization: false,
      };
    }
    case APIGetOrganization.FAILURE: {
      return {
        ...state,
        loadingCurrentOrganization: false,
      };
    }
    case CreateOrganization:
    case UpdateOrganization: {
      return {
        ...state,
        savingCurrentOrganization: true,
      };
    }
    case APICreateOrganization.SUCCESS:
    case APIUpdateOrganization.SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        organizations: {
          ...state.organizations,
          [data.id]: data,
        },
        savingCurrentOrganization: false,
      };
    }
    case APICreateOrganization.FAILURE:
    case APIUpdateOrganization.FAILURE: {
      return {
        ...state,
        savingCurrentOrganization: false,
      };
    }
    case GetOrganizationUsers: {
      return {
        ...state,
        loadingCurrentOrganizationUsers: true,
      };
    }
    case APIGetOrganizationUsers.SUCCESS: {
      const data = action.payload;

      const orgId = (action as APIGetOrganizationUsersAction).requestPayload.id;

      const usersById = data.users.reduce(function (
        result: { [key: string]: User },
        item: User,
      ) {
        result[item.id] = item;
        return result;
      },
      {});

      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          [orgId]: usersById,
        },
        loadingCurrentOrganizationUsers: false,
      };
    }
    case APIGetOrganizationUsers.FAILURE: {
      return {
        ...state,
        loadingCurrentOrganizationUsers: false,
      };
    }
    case AddUserToOrganization:
    case UpdateUserInOrganization: {
      return {
        ...state,
        savingUser: true,
      };
    }
    case APIAddUserToOrganization.SUCCESS:
    case APIUpdateUserInOrganization.SUCCESS: {
      const data = action.payload;

      const {
        orgId,
        userId,
      } = (action as APIAddUserToOrganizationAction).requestPayload;

      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          [orgId]: {
            ...state.organizationsUsers[orgId],
            [userId]: data,
          },
        },
        savingUser: false,
      };
    }
    case APIAddUserToOrganization.FAILURE:
    case APIUpdateUserInOrganization.FAILURE: {
      return {
        ...state,
        savingUser: false,
      };
    }
    case RemoveUserFromOrganization: {
      const { orgId, userId } = action.payload;

      const orgUsers = Object.assign({}, state.organizationsUsers[orgId]);
      delete orgUsers[userId];

      return {
        ...state,
        organizationsUsers: {
          ...state.organizationsUsers,
          [orgId]: orgUsers,
        },
      };
    }
    case Logout: {
      return initialState;
    }
    default:
      break;
  }

  return state;
}

export default reducer;
