import {
  APIActionTypes,
  APIRequest,
  APIGetSensoryAnalysis,
  APIGetSensoryAnalysesForUser,
  APIUserConnect,
  APIUpdateSensoryAnalysis,
  APIDeleteSensoryAnalysis,
  APIGetOrganizations,
  APIGetOrganization,
  APICreateOrganization,
  APIUpdateOrganization,
  APIGetOrganizationUsers,
  APIAddUserToOrganization,
  APIUpdateUserInOrganization,
  APIRemoveUserFromOrganization,
  APIGetSensoryAnalysesForOrganization,
  APICreateDefectsAnalysis,
  APIGetDefectsAnalysis,
  APIUpdateDefectsAnalysis,
  APIGetGranulometryAnalysis,
  APICreateGranulometryAnalysis,
  APIUpdateGranulometryAnalysis,
  APIExportDataToExcel,
} from './types';
import { SensoryAnalysis } from '../../models/sensory';
import { Defect } from '../../models/defects';

export function apiUserConnect(): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/users/connect',
    meta: {
      method: 'GET',
    },
    baseAction: APIUserConnect,
  };
}

export function apiGetSensoryAnalysesForUser(): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/sensory',
    meta: {
      method: 'GET',
    },
    baseAction: APIGetSensoryAnalysesForUser,
  };
}

export function apiGetSensoryAnalysesForOrganization(): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/sensory/organization',
    meta: {
      method: 'GET',
    },
    baseAction: APIGetSensoryAnalysesForOrganization,
  };
}

export function apiGetSensoryAnalysesForOrganizationById(
  orgId: string,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/sensory/organization/${orgId}`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetSensoryAnalysesForOrganization,
  };
}

export function apiGetSensoryAnalysis(id: string): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/sensory/${id}`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetSensoryAnalysis,
  };
}

function sensoryAnalysisToAPIObject(sensory: SensoryAnalysis) {
  return {
    fragranceAroma: {
      score: sensory.fragranceAroma.score,
      descriptors: sensory.fragranceAroma.descriptors,
    },
    flavor: {
      score: sensory.flavor.score,
      descriptors: sensory.flavor.descriptors,
    },
    aftertaste: {
      score: sensory.aftertaste.score,
      descriptors: sensory.aftertaste.descriptors,
    },
    acidity: {
      score: sensory.acidity.score,
      descriptors: sensory.acidity.descriptors,
    },
    body: {
      score: sensory.body.score,
      descriptors: sensory.body.descriptors,
    },
    balance: {
      score: sensory.balance.score,
      descriptors: sensory.balance.descriptors,
    },
    overall: {
      score: sensory.overall.score,
    },
    uniformity: {
      cup1: sensory.uniformity.cup1,
      cup2: sensory.uniformity.cup2,
      cup3: sensory.uniformity.cup3,
      cup4: sensory.uniformity.cup4,
      cup5: sensory.uniformity.cup5,
    },
    cleanCup: {
      cup1: sensory.cleanCup.cup1,
      cup2: sensory.cleanCup.cup2,
      cup3: sensory.cleanCup.cup3,
      cup4: sensory.cleanCup.cup4,
      cup5: sensory.cleanCup.cup5,
    },
    sweetness: {
      cup1: sensory.sweetness.cup1,
      cup2: sensory.sweetness.cup2,
      cup3: sensory.sweetness.cup3,
      cup4: sensory.sweetness.cup4,
      cup5: sensory.sweetness.cup5,
    },
    defects: {
      intensity: sensory.defects.intensity,
      cups: sensory.defects.cups,
      descriptors: sensory.defects.descriptors,
    },
    notes: sensory.notes,
    profile: sensory.profile,
    companyCode: sensory.companyCode,
  };
}

export function apiUpdateSensoryAnalysis(
  id: string,
  sensory: SensoryAnalysis,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/sensory/${id}`,
    meta: {
      method: 'PUT',
      body: sensoryAnalysisToAPIObject(sensory),
    },
    baseAction: APIUpdateSensoryAnalysis,
  };
}

export function apiCreateSensoryAnalysis(
  sensory: SensoryAnalysis,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/sensory',
    meta: {
      method: 'POST',
      body: sensoryAnalysisToAPIObject(sensory),
    },
    baseAction: APIUpdateSensoryAnalysis,
  };
}

export function apiDeleteSensoryAnalysis(id: string): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/sensory/${id}`,
    meta: {
      method: 'DELETE',
    },
    baseAction: APIDeleteSensoryAnalysis,
  };
}

export function apiGetOrganizations(): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/organizations',
    meta: {
      method: 'GET',
    },
    baseAction: APIGetOrganizations,
  };
}

export function apiGetOrganization(id: string): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/organizations/${id}`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetOrganization,
  };
}

export function apiCreateOrganization(
  name: string,
  products: Array<string>,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: '/organizations',
    meta: {
      method: 'POST',
      body: {
        name,
        productTypes: products,
      },
    },
    baseAction: APICreateOrganization,
  };
}

export function apiUpdateOrganization(
  id: string,
  name: string,
  products: Array<string>,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: `/organizations/${id}`,
    meta: {
      method: 'PUT',
      body: {
        name,
        productTypes: products,
      },
    },
    baseAction: APIUpdateOrganization,
  };
}

export function apiGetOrganizationUsers(id: string): APIActionTypes {
  return {
    type: APIRequest,
    payload: { id },
    url: `/organizations/${id}/users`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetOrganizationUsers,
  };
}

export function apiAddUserToOrganization(
  orgId: string,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  roles: Array<string>,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: { orgId, email, firstName, lastName, password, roles },
    url: `/organizations/${orgId}/users`,
    meta: {
      method: 'POST',
      body: {
        email,
        firstName,
        lastName,
        password,
        roles,
      },
    },
    baseAction: APIAddUserToOrganization,
  };
}

export function apiUpdateUserInOrganization(
  orgId: string,
  userId: string,
  firstName: string,
  lastName: string,
  roles: Array<string>,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: { orgId, userId, firstName, lastName, roles },
    url: `/organizations/${orgId}/users/${userId}`,
    meta: {
      method: 'PUT',
      body: {
        firstName,
        lastName,
        roles,
      },
    },
    baseAction: APIUpdateUserInOrganization,
  };
}

export function apiRemoveUserFromOrganization(
  orgId: string,
  userId: string,
): APIActionTypes {
  return {
    type: APIRequest,
    payload: { orgId, userId },
    url: `/organizations/${orgId}/users/${userId}`,
    meta: {
      method: 'DELETE',
    },
    baseAction: APIRemoveUserFromOrganization,
  };
}

export function apiGetDefectsAnalysis(companyCode: string, userId: string) {
  return {
    type: APIRequest,
    payload: { companyCode, userId },
    url: `/defects?companyCode=${companyCode}&userId=${userId}`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetDefectsAnalysis,
  };
}

export function apiCreateDefectsAnalysis(
  companyCode: string,
  black: Defect,
  crystal: Defect,
  cutOrNipped: Defect,
  fadedAmber: Defect,
  fadedOldish: Defect,
  fadedOverDried: Defect,
  fadedStreaked: Defect,
  immature: Defect,
  insectDamage: Defect,
  moldy: Defect,
  pressedOrCrushed: Defect,
  shrunk: Defect,
  sour: Defect,
  wetOrUnderDried: Defect,
  totalWeight: number,
) {
  return {
    type: APIRequest,
    payload: {
      companyCode,
      black,
      crystal,
      cutOrNipped,
      fadedAmber,
      fadedOldish,
      fadedOverDried,
      fadedStreaked,
      immature,
      insectDamage,
      moldy,
      pressedOrCrushed,
      shrunk,
      sour,
      wetOrUnderDried,
      totalWeight,
    },
    url: '/defects',
    meta: {
      method: 'POST',
      body: {
        companyCode,
        black,
        crystal,
        cutOrNipped,
        fadedAmber,
        fadedOldish,
        fadedOverDried,
        fadedStreaked,
        immature,
        insectDamage,
        moldy,
        pressedOrCrushed,
        shrunk,
        sour,
        wetOrUnderDried,
        totalWeight,
      },
    },
    baseAction: APICreateDefectsAnalysis,
  };
}

export function apiUpdateDefectsAnalysis(
  id: string,
  companyCode: string,
  black: Defect,
  crystal: Defect,
  cutOrNipped: Defect,
  fadedAmber: Defect,
  fadedOldish: Defect,
  fadedOverDried: Defect,
  fadedStreaked: Defect,
  immature: Defect,
  insectDamage: Defect,
  moldy: Defect,
  pressedOrCrushed: Defect,
  shrunk: Defect,
  sour: Defect,
  wetOrUnderDried: Defect,
  totalWeight: number,
) {
  return {
    type: APIRequest,
    payload: {
      companyCode,
      black,
      crystal,
      cutOrNipped,
      fadedAmber,
      fadedOldish,
      fadedOverDried,
      fadedStreaked,
      immature,
      insectDamage,
      moldy,
      pressedOrCrushed,
      shrunk,
      sour,
      wetOrUnderDried,
      totalWeight,
    },
    url: `/defects/${id}`,
    meta: {
      method: 'PUT',
      body: {
        companyCode,
        black,
        crystal,
        cutOrNipped,
        fadedAmber,
        fadedOldish,
        fadedOverDried,
        fadedStreaked,
        immature,
        insectDamage,
        moldy,
        pressedOrCrushed,
        shrunk,
        sour,
        wetOrUnderDried,
        totalWeight,
      },
    },
    baseAction: APIUpdateDefectsAnalysis,
  };
}

export function apiGetGranulometryAnalysis(
  companyCode: string,
  userId: string,
) {
  return {
    type: APIRequest,
    payload: { companyCode, userId },
    url: `/granulometry?companyCode=${companyCode}&userId=${userId}`,
    meta: {
      method: 'GET',
    },
    baseAction: APIGetGranulometryAnalysis,
  };
}

export function apiCreateGranulometryAnalysis(
  companyCode: string,
  mesh1: number,
  mesh2: number,
  mesh3: number,
  mesh4: number,
  mesh5: number,
  mesh6: number,
  mesh7: number,
  final: number,
  totalWeight: number,
) {
  return {
    type: APIRequest,
    payload: {
      companyCode,
      mesh1,
      mesh2,
      mesh3,
      mesh4,
      mesh5,
      mesh6,
      mesh7,
      final,
      totalWeight,
    },
    url: '/granulometry',
    meta: {
      method: 'POST',
      body: {
        companyCode,
        mesh1,
        mesh2,
        mesh3,
        mesh4,
        mesh5,
        mesh6,
        mesh7,
        final,
        totalWeight,
      },
    },
    baseAction: APICreateGranulometryAnalysis,
  };
}

export function apiUpdateGranulometryDefectsAnalysis(
  id: string,
  companyCode: string,
  mesh1: number,
  mesh2: number,
  mesh3: number,
  mesh4: number,
  mesh5: number,
  mesh6: number,
  mesh7: number,
  final: number,
  totalWeight: number,
) {
  return {
    type: APIRequest,
    payload: {
      companyCode,
      mesh1,
      mesh2,
      mesh3,
      mesh4,
      mesh5,
      mesh6,
      mesh7,
      final,
      totalWeight,
    },
    url: `/granulometry/${id}`,
    meta: {
      method: 'PUT',
      body: {
        companyCode,
        mesh1,
        mesh2,
        mesh3,
        mesh4,
        mesh5,
        mesh6,
        mesh7,
        final,
        totalWeight,
      },
    },
    baseAction: APIUpdateGranulometryAnalysis,
  };
}

export function apiExportDataToExcel(orgId: string | null): APIActionTypes {
  return {
    type: APIRequest,
    payload: {},
    url: orgId ? `/export/all?orgId=${orgId}` : '/export/all',
    meta: {
      method: 'GET',
    },
    baseAction: APIExportDataToExcel,
  };
}
