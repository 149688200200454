import { withPrefix } from '../utils';
import { UserRole } from '../../models/user';

const Name = 'User';

export const LoginWithEmailPassword = withPrefix(
  Name,
  'LoginWithEmailPassword',
);
export const SilentLogin = withPrefix(Name, 'SilentLogin');
export const LoginSuccessful = withPrefix(Name, 'LoginSuccessful');
export const LoginFailed = withPrefix(Name, 'LoginFailed');
export const Logout = withPrefix(Name, 'Logout');

// Actions types
export interface LoginWithEmailPasswordAction {
  type: typeof LoginWithEmailPassword;
  payload: { email: string; password: string };
}

export interface SilentLoginAction {
  type: typeof SilentLogin;
  payload: {};
}

export interface LoginSuccessfulAction {
  type: typeof LoginSuccessful;
  payload: {
    token: string;
    id: string;
    name: string;
    email: string;
    photoUrl: string;
  };
}

export interface LoginFailedAction {
  type: typeof LoginFailed;
  payload: { reason: string };
}

export interface LogoutAction {
  type: typeof Logout;
  payload: {};
}

export type UserActionTypes =
  | LoginWithEmailPasswordAction
  | SilentLoginAction
  | LoginSuccessfulAction
  | LoginFailedAction
  | LogoutAction;

// Reducer state
export type UserState = {
  id: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  photoUrl: string | null;
  token: string | null;
  errors: {
    login: string | null;
  };
  loginInProgress: boolean;
  roles: Array<UserRole>;
  organizationId: string | null;
};

export enum UserLoginError {
  WrongPassword = 'WrongPassword',
  InvalidEmail = 'InvalidEmail',
}
